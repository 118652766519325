import api from '@/utils/api'

export default {
    getFilteredChargeUnits(search, page, size) {
        let query = '?range=' + encodeURIComponent(`[${page - 1},${size}]`) + '&filter=' + encodeURIComponent(isNaN(search) ? `{"title":"%${search}%"}` : `{"code":"${search}"}`) + `&sort=${encodeURIComponent('[id, DESC]')}`
        return api.get(`charge-units` + query)
    },

    getChargeUnits(page, size) {
        let range = '?range=' + encodeURIComponent(`[${page - 1},${size}]`)
        return api.get(`charge-units` + (page ? range : ''))
    },

    getChargeUnit(id) {
        return api.get(`charge-units/${id}`)
    },

    createChargeUnit(data) {
        return api.post('charge-units', data)
    },

    updateChargeUnit(id, data) {
        return api.put(`charge-units/${id}`, data)
    },

    getChargeUnitsByLevel(level) {
        return api.get(`charge-units/of-level/${level}`)
    },

    getAllChargeUnits() {
        return api.get(`charge-units/all`)
    },

    deleteChargeUnit(id) {
        return api.delete(`charge-units/${id}`)
    }
}
